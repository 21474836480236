<template>
  <div>
    <validation-observer ref="supportServiceSubCategoryForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input name="category" label="Support Service Category*">
          <el-select
            class="w-100"
            v-model="request.supportServiceSubCategoryForm.category"
            placeholder="Select Category">
            <el-option
              class="select-danger"
              :value="0"
              label="Select Category"
              key="0">
            </el-option>
            <el-option
              v-for="option in response.categories"
              class="select-danger max-width-450"
              :value="option.id"
              :label="option.name"
              :key="option.id">
            </el-option>
          </el-select>
        </base-input>
        <base-input
          type="text"
          :rules="{required: true}"
          name="name"
          v-model="request.supportServiceSubCategoryForm.name"
          label="Support Service Sub Category Name*"
          placeholder="Name">
        </base-input>
        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="hideForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            Save
          </base-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "SupportServiceSubCategoryForm",
  props: ['supportServiceSubCategoryDataToEdit'],
  data() {
    return {
      request: {
        supportServiceSubCategoryForm: {
          name: '',
          category: 0
        }
      },
      response: {
        categories: [],
      },
    }
  },
  watch: {
    /**
     * Save new value to supportServiceSubCategoryDataToEdit property
     * @param newVal - New Value of supportServiceSubCategoryDataToEdit property
     */
    supportServiceSubCategoryDataToEdit: function (newVal) {
      this.request.supportServiceSubCategoryForm.name = newVal.name ?? ''
      this.request.supportServiceSubCategoryForm.category = newVal.support_service_category_id ?? 0
    }
  },
  methods: {

    /**
     * Fetch all the support service categories
     */
    async getAllSupportServiceCategories() {
      let vm = this
      try {
        const response = await vm.$store.dispatch('_getAllSupportServiceCategories')
        vm.response.categories = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Service Sub Category',
          message: message
        })
      }
    },

    /**
     * Validates the form field, submit it and emit relevant function
     */
    async submitForm() {
      let vm = this
      const isSupportServiceSubCategoryFormValid = await this.$refs['supportServiceSubCategoryForm'].validate();
      if (isSupportServiceSubCategoryFormValid) {
        if(this.$store.state.modals.addSupportServiceSubCategory) {
          vm.$emit('storeSupportServiceSubCategory', vm.request.supportServiceSubCategoryForm)
        } else {
          vm.$emit('updateSupportServiceSubCategory', vm.request.supportServiceSubCategoryForm)
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    /**
     * Hide the form
     */
    hideForm() {
      if(this.$store.state.modals.addSupportServiceSubCategory) {
        this.resetForm()
        this.$store.commit('hideAddSupportServiceSubCategoryModal')
      } else {
        this.$store.commit('hideEditSupportServiceSubCategoryModal')
      }
    },

    /**
     * Reset the Support service sub category form fields
     */
    resetForm(){
      this.request.supportServiceSubCategoryForm.name = ''
      this.request.supportServiceSubCategoryForm.category = ''
      this.$refs.supportServiceSubCategoryForm.reset();
    }
  }
}
</script>

<style scoped>

.max-width-450{
  max-width: 450px;
}

</style>
