import { render, staticRenderFns } from "./SupportServiceSubCategoryForm.vue?vue&type=template&id=0583c008&scoped=true&"
import script from "./SupportServiceSubCategoryForm.vue?vue&type=script&lang=js&"
export * from "./SupportServiceSubCategoryForm.vue?vue&type=script&lang=js&"
import style0 from "./SupportServiceSubCategoryForm.vue?vue&type=style&index=0&id=0583c008&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0583c008",
  null
  
)

export default component.exports