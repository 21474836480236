<template>
  <modal v-loading="loaders.supportServiceSubCategory" :show.sync="$store.state.modals.editSupportServiceSubCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Edit Support Service Sub Category</h1>
    <support-service-sub-category-form
      @updateSupportServiceSubCategory="updateSupportServiceSubCategory"
      :support-service-sub-category-data-to-edit="response.supportServiceSubCategory"
      ref="SupportServiceSubCategoryForm">
    </support-service-sub-category-form>
  </modal>
</template>

<script>

import SupportServiceSubCategoryForm from "@/views/Pages/ContentSection/SupportService/SupportServiceSubCategory/SupportServiceSubCategoryForm";

export default {
  name: "EditSupportServiceSubCategoryModal",
  props: ['id'],
  components: {
    SupportServiceSubCategoryForm
  },
  data() {
    return {
      loaders: {
        supportServiceSubCategory: false
      },
      editSubCategoryId: '',
      response: {
        supportServiceSubCategory: ''
      }
    }
  },
  watch: {
    /**
     * Watcher to check newVal of Id prop
     * @param {number} newVal - New Value of the Id prop
     */
    id: function (newVal) {
      if (newVal != null) {
        this.editSubCategoryId = newVal
        this.getSupportServiceSubCategory()
      }
    }
  },
  methods: {

    /**
     * call to fetch all support service categories
     */
    fetchCategories() {
      this.$refs.SupportServiceSubCategoryForm.getAllSupportServiceCategories()
    },

    /**
     * Get the relevant support service sub category
     */
    async getSupportServiceSubCategory() {
      let vm = this
      vm.loaders.supportServiceSubCategory = true
      const payload = { id: vm.editSubCategoryId }
      try {
        const response = await vm.$store.dispatch('_getSupportServiceSubCategory', payload)
        vm.response.supportServiceSubCategory = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Edit Support Service Sub Category',
          message: message
        })
      }
      vm.loaders.supportServiceSubCategory = false
    },

    /**
     * Update support service sub category
     * @param {event} event - Event Object
     */
    async updateSupportServiceSubCategory(event) {
      let vm = this
      vm.loaders.supportServiceSubCategory = true
      const formData = {}
      Object.assign(formData,event)
      formData.id = vm.editSubCategoryId
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      try {
        await vm.$store.dispatch('_updateSupportServiceSubCategory', {config, formData})
        vm.$notify.success({
          title: 'Success',
          message: 'Support Service Sub Category updated successfully.'
        })
        vm.$store.commit('hideEditSupportServiceSubCategoryModal')
        vm.$emit('updateSupportServiceSubCategories')
      }
      catch (error) {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Edit Support Service Sub Category',
          dangerouslyUseHTMLString: true,
          message: message
        })
      }
      vm.loaders.supportServiceSubCategory = false
    },

    /**
     * Generate error message from array of errors
     * @param arr - Array of errors message
     * @returns {string} message
     */
    generateErrorMessage(arr) {
      let message = ''
      for (let item in arr) {
        message += '<li>' + arr[item] + '</li>'
      }
      return message
    },
  }
}
</script>

<style scoped>

</style>
