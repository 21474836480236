<template>
  <modal v-loading="loaders.supportServiceSubCategory" :show.sync="$store.state.modals.addSupportServiceSubCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Add Support Service Sub Category</h1>
    <support-service-sub-category-form
      @storeSupportServiceSubCategory="storeSupportServiceSubCategory"
      ref="SupportServiceSubCategoryForm">
    </support-service-sub-category-form>
  </modal>
</template>

<script>

import SupportServiceSubCategoryForm from "@/views/Pages/ContentSection/SupportService/SupportServiceSubCategory/SupportServiceSubCategoryForm";

export default {
  name: "AddSupportServiceSubCategoryModal",
  components: {
    SupportServiceSubCategoryForm
  },
  data() {
    return {
      loaders: {
        supportServiceSubCategory: false
      }
    }
  },
  methods: {

    /**
     * call to fetch all support service categories
     */
    fetchCategories() {
      this.$refs.SupportServiceSubCategoryForm.getAllSupportServiceCategories()
    },

    /**
     * Store the new support service sub category
     * @param {event} event - Event Object
     */
    async storeSupportServiceSubCategory(event) {
      let vm = this
      vm.loaders.supportServiceSubCategory = true
      const formData = {}
      Object.assign(formData,event)
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      try {
        await vm.$store.dispatch('_storeSupportServiceSubCategory', {config, formData})
        vm.$notify.success({
          title: 'Success',
          message: 'Support Service Sub Category created successfully.'
        })
        this.$refs.SupportServiceSubCategoryForm.resetForm()
        vm.$store.commit('hideAddSupportServiceSubCategoryModal')
        vm.$emit('updateSupportServiceSubCategories')
      }
      catch (error) {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Add Support Service Sub Category',
          dangerouslyUseHTMLString: true,
          message: message
        })
      }
      vm.loaders.supportServiceSubCategory = false
    },

    /**
     * Generate error message from array of errors
     * @param arr - Array of errors message
     * @returns {string} message
     */
    generateErrorMessage(arr) {
      let message = ''
      for (let item in arr) {
        message += '<li>' + arr[item] + '</li>'
      }
      return message
    },
  }
}
</script>

<style scoped>

</style>
